import React, { useState, useEffect } from "react";
import { Segment, Button, Header, Divider } from "semantic-ui-react";
import { getProductsWithMoves } from "../../actions/dashboard";
import BusinessclientFilter from "../Filters/BusinessclientFilter";
import ProductFilterList from "../Filters/ProductFilterList";
import Bar2periods from "./bar2periods";
import Barchart from "./barchart";
import Linestockbalance from "./line_day_stock";
import LineProductSales from "./products_chart";
import Pieproductpercent from "./pie_productpercent";
import Pieproductstatus from "./pie_productstatus";
import { DatePicker } from "rsuite";
import WarehouseFilter from "../Filters/WarehouseFilter";
import { useUpdateContext } from "../../context/UpdateContext";
import moment from "moment";

const itemStyle = {
  margin: "2px",
  minWidth: "330px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};

const Dashboard = (props) => {
  const {
    businessclient,
    warehouse,
    handleDateToChange,
    resetFilters,
    product,
  } = useUpdateContext();

  const [isLoading, setLoading] = useState(false);
  const [update, startUpdate] = useState(false);
  const [dashProductsLoading, setDashProductsLoading] = useState(false);
  const [dateFrom, setDateFrom] = useState(moment().startOf("month"));
  const [dateTo, setDateTo] = useState();

  const filters = {
    businessclient,
    warehouse,
    handleDateToChange,
    resetFilters,
    product,
    dateFrom,
    dateTo,
  };
  useEffect(() => {
    setDashProductsLoading(true);
    getProductsWithMoves({ warehouse, businessclient, dateFrom, dateTo }).then(
      (resp) => {
        setDashProductsLoading(false);
        if (Array.isArray(resp)) {
          localStorage.setItem("dashProducts", resp);
        }
      }
    );

    // //update Dashboard default products once a day (avoid mulpiple requests per day)
    // const today = new Date();

    // if (
    //   !localStorage.getItem("dashDate") ||
    //   new Date(localStorage.getItem("dashDate")).getDate() < today.getDate()
    // ) {
    //   localStorage.setItem("dashDate", today);
    // }
    // // END -||-

    // if (!localStorage.getItem("dashProducts") && !product) {
    //   setLoading(true);
    //   getProductsWithMoves({warehouse,businessclient,dateFrom,dateTo}).then((resp) => {
    //     if (Array.isArray(resp)) {
    //       localStorage.setItem("dashProducts", resp);
    //     }
    //   });
    // }
    // //  else handleProductChange(JSON.parse(localStorage.getItem("dashProducts")));
  }, [warehouse, businessclient, dateFrom, dateTo]);

  // if (!product) return <div> Getting last 10 days movements</div>;

  return (
    <div>
      <div
        // className="flex--left"
        style={{ margin: "10px", width: "900px" }}
      >
        <Header as="h2">Dashboard</Header>
        {/* {dashProductsLoading && (
          
            <Placeholder>
              <PlaceholderHeader image>
                <PlaceholderLine />
              </PlaceholderHeader>
            </Placeholder>
          
        )}
        {!dashProductsLoading && <div>default products</div>} */}
      </div>
      <Segment style={{ maxWidth: "1000px", margin: "40px 0px 0px 20px" }}>
        <BusinessclientFilter
          useFilter={true}
          // style={itemStyle}
          name="businessclient"
          disabled={isLoading}
          fluid={false}
        />

        <ProductFilterList fluid={false} multiple={true} disabled={isLoading} />

        <WarehouseFilter style={itemStyle} disabled={isLoading} fluid={false} />
        <Divider></Divider>

        <div className="flex--left">
          <DatePicker
            disabled={isLoading}
            oneTap
            value={dateFrom}
            isClearable={true}
            // placement="leftStart"
            format="DD-MM-YYYY"
            onChange={(date) => setDateFrom(date)}
            placeholder="from..."
            style={itemStyle}
          ></DatePicker>

          <DatePicker
            disabled={isLoading}
            oneTap
            value={dateTo}
            isClearable={true}
            // placement="leftStart"
            format="DD-MM-YYYY"
            onChange={(date) => setDateTo(date)}
            placeholder="to..."
            style={itemStyle}
          ></DatePicker>
        </div>

        <Divider></Divider>
        <Button
          // loading={dashProductsLoading}
          // disabled={dashProductsLoading}
          color={
            (!product || product.length === 0) && !dashProductsLoading
              ? "blue"
              : dashProductsLoading
              ? "grey"
              : "green"
          }
          fluid={true}
          style={{ maxWidth: "330px" }}
          onClick={(e, data) => startUpdate(!update)}
        >
          {product && product.length > 0
            ? "Update"
            : dashProductsLoading && "Getting best mover products..."}

          {(!product || product.length === 0) &&
            !dashProductsLoading &&
            "Update with best movers"}
        </Button>
      </Segment>

      {localStorage.getItem("dashProducts") && (
        <div className="flex--left">
          <LineProductSales
            filters={filters}
            update={update}
            nrdays={15}
          ></LineProductSales>
          <Linestockbalance
            filters={filters}
            update={update}
            nrdays={15}
          ></Linestockbalance>

          <Bar2periods
            filters={filters}
            update={update}
            nrdays={15}
          ></Bar2periods>
          <Barchart filters={filters} update={update} nrdays={15}></Barchart>
          <Pieproductpercent
            filters={filters}
            update={update}
            nrdays={15}
          ></Pieproductpercent>
          <Pieproductstatus
            filters={filters}
            update={update}
            nrdays={15}
          ></Pieproductstatus>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
